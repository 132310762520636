import { ActionType, action } from "typesafe-actions";
export enum Routes {
    home = "/",
    tutorial = "/tutorial",
    mapView = "/map-view",
    data = "/data",
    dataManage = "/data/manage",
    mapTemplateData = "/data/map-template",
    listView = "/explore/list-view",
    exploreMapView = "/explore/map-view",
    export = "/export",
    datasets = "/datasets",
    datasources = "/datasources",
    configuration = "/configuration",

    velocity = "/velocity",
    velocityApiKey = "/velocity/api-key",

    availableAreas = "/available-areas",
    savedViews = "/saved-views",
    savedView = "savedViewId",

    explore = "/explore",
    exploreDataset = "/explore-dataset",
    unlocked = "/unlocked",
    accessKeys = "/access-keys",

    mapTemplates = "/map-templates",
    mapTemplatesCreate = "/map-templates/create",
    mapTemplatesManage = "/map-templates/manage",

    mapTemplateSavedViews = "/map-templates/saved-views",

    onlyInSnowflake = "/only-in-snowflake",

    recipes = "/recipes",
    team = "/team",

    featureRequestAndIssues = "https://datascape.featurebase.app",
    feedback = "https://datascape.featurebase.app/?b=66aabcb6f611e8ff2d09cbe1",
    roadmap = "https://datascape.featurebase.app/roadmap",
    changeLog = "https://datascape.featurebase.app/changelog",
    support = "https://datascape.featurebase.app/help",
    installationGuide = "https://datascape.featurebase.app/help/collections/6943641-install-in-snowflake",
    joinBigGeoSlack = "https://join.slack.com/t/biggeocommunity/shared_invite/zt-2rdvooqsl-JrfuBv6DiopCsSVgKHr~0Q",

    bigGeoProductsDatascape = "https://biggeo.com/products/datascape",
    bigGeoProductsDatalab = "https://www.biggeo.com/products/datalab",
    bigGeoProductsVelocity = "https://biggeo.com/products/velocity",
    savedAreas = "https://datascape.featurebase.app/help/articles/6177512-what-are-saved-areas",

    //Datascape Snowflake Marketplace
    datascapeSnowflakeMarketplace = "https://app.snowflake.com/marketplace/listing/GZTYZOBOJLN/biggeo-biggeo-datascape-ai?search=datascape",

    // MARK: Prioritize Links
    prioritizeFillFeature = "https://datascape.featurebase.app/p/auto-polygon-filler",
    prioritizeDrawFeature = "https://datascape.featurebase.app/p/freehand-shape-drawing",
    prioritizeDataFeature = "https://datascape.featurebase.app/p/draw-shapes-from-rendered-data",

    // MARK: Learn More Links
    learnMoreShapeLayersFeature = "https://datascape.featurebase.app/help/articles/0106696-what-are-shape-layers",
    learnMoreDataManagement = "https://datascape.featurebase.app/help/collections/1203578-data-management",

    signIn = "/sign-in",
    signUp = "/sign-up",
}

export type AllRoutes = Routes;

export const actions = {
    toRoute: (p: `${AllRoutes}/${string}` | AllRoutes | string) =>
        action("TO_ROUTE", p),
};

export const navigationActions = actions;

export type NavigationActionType = ActionType<typeof actions>;
