import { Applications, getCookies } from "@biggeo/bg-common";
import { AuthenticatedUser, User } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Failure,
    Initialized,
    Pending,
    RemoteData,
    Success,
    isFailure,
    isSuccess,
    map,
} from "@vividtheory/remotedata";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import { match } from "ts-pattern";
import { ActionType, action } from "typesafe-actions";
import Cookies from "universal-cookie";

export const userRootStateKey = "user";

interface IModel {
    readonly userRemoteData: RemoteData<string, AuthenticatedUser>;
    readonly userUuid?: string;
}

const initialState: IModel = {
    userRemoteData: new Initialized(),
    userUuid: undefined,
};

export const userActions = {
    setUserRemoteData: (v: RemoteData<string, AuthenticatedUser>) =>
        action("SET_USER_REMOTE_DATA", v),
    updateUser: (user: User) => action("UPDATE_USER_REMOTE_DATA", user),
    onAuthenticationValidation: (i: {
        v?: RemoteData<string, AuthenticatedUser>;
        type: "failure" | "success" | "request";
    }) => action("ON_AUTHENTICATION_VALIDATION", i),
    remove: () => action("REMOVE"),
};

const cookies = new Cookies();
export const usersReducer = (
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    state: IModel = initialState,
    action: ActionType<typeof userActions>
): IModel => {
    if (!action) return state;

    const appCookies = getCookies(Applications.datascape);

    switch (action.type) {
        case "UPDATE_USER_REMOTE_DATA":
            return {
                ...state,
                userRemoteData: map(
                    (user) => ({
                        ...user,
                        user: {
                            ...user.user,
                            ...omitBy(action.payload, isUndefined || isNull),
                        },
                    }),
                    state.userRemoteData
                ),
            };

        case "SET_USER_REMOTE_DATA": {
            if (isSuccess(action.payload)) {
                cookies.set(appCookies.jwt, action.payload.data.jwt, {
                    path: "/",
                    maxAge: 2592000, // One Month
                    secure: true,
                });
                return {
                    ...state,
                    userRemoteData: new Success(action.payload.data),
                };
            }
            return {
                ...state,
                userRemoteData: action.payload,
            };
        }

        case "ON_AUTHENTICATION_VALIDATION": {
            return {
                ...state,
                userRemoteData: match(action.payload.type)
                    .with("request", () => new Pending())
                    .with("success", () => {
                        if (action.payload.v && isSuccess(action.payload.v)) {
                            cookies.set(
                                appCookies.jwt,
                                action.payload.v.data.jwt,
                                {
                                    path: "/",
                                    maxAge: 2592000, // One Month
                                    secure: true,
                                }
                            );

                            return new Success(action.payload.v.data);
                        }
                        return new Initialized();
                    })
                    .with("failure", () => {
                        if (action.payload.v && isFailure(action.payload.v)) {
                            cookies.remove(appCookies.jwt, {
                                path: "/",
                                secure: true,
                            });

                            return new Failure(action.payload.v.error);
                        }
                        return new Initialized();
                    })
                    .exhaustive(),
            };
        }

        case "REMOVE":
            cookies.remove(appCookies.jwt, { path: "/", secure: true });
            return { ...initialState };

        default:
            return state;
    }
};
